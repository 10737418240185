import { BaseDialog } from "./BaseDialog";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";
import { LoginForm } from "../forms/LoginForm";

function LoginDialog(props) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isRegisterOpen,
    state.setIsRegisterOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      size="md"
      modalTitle={
        <div className="">
          <h1 className="pl-2 pt-1 text-left text-xl font-semibold leading-tight tracking-tight text-black dark:text-white md:text-2xl">
            Unlock Past Grids
          </h1>
        </div>
      }
      modalBody={<ModalBody />}
    />
  );
}

function ModalBody() {
  const [incorrectGuesses, correctGuesses] = usePersistentStore((state) => [
    state.incorrectGuesses,
    state.correctGuesses,
  ]);

  return (
    <>
      <div className="p-2 text-sm text-gray-700 sm:text-md">
        Play past grids with a free Escape Collective account!
      </div>

      <div className="mt-2 space-y-3 p-2">
        <a
          href={`https://${process.env.NEXT_PUBLIC_SSO_URL}/sso/?r=https://${process.env.NEXT_PUBLIC_CALLBACK_URL}/account/callback`}
          className="block w-full rounded-lg bg-orange-400 px-5 py-2.5 text-center text-md font-medium text-black hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 "
        >
          Sign In
        </a>

        {/* <a
          href={`https://${process.env.NEXT_PUBLIC_SSO_URL}/sso/?r=https://${process.env.NEXT_PUBLIC_CALLBACK_URL}/account/callback`}
          className="block w-full rounded-lg bg-gray-200 px-5 py-2.5 text-center text-md font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          Sign In
        </a> */}
      </div>

      {/* <div className="space-y-4 p-2 py-4">
        <LoginForm />
        <p className="pt-1 text-md font-normal text-gray-500">
          Not registered? &nbsp;
          <a
            href="/account/register"
            className="font-medium text-red-600 hover:underline dark:text-red-500"
          >
            Create an account
          </a>
        </p>
      </div> */}
    </>
  );
}

export default LoginDialog;
