import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import usePersistentStore from "../../lib/store/PersistentStore";
import { IconCards } from "../icons/IconCards";
import { IconCardsOutline } from "../icons/IconCardsOutline";
import { IconQuestionCircle } from "../icons/IconQuestionCircle";
import { IconUser } from "../icons/IconUser";
import useGameStore from "../../lib/store/GameStore";
import { IconSparklesSharp } from "../icons/IconSparklesSharp";
import Link from "next/link";
import { IconChevronDown } from "../icons/IconChevronDown";
import { IconAdminLine } from "../icons/IconAdminLine";

export default function AccountMenu() {
  const [cardMode, toggleCardMode, darkMode, toggleDarkMode] =
    usePersistentStore((state) => [
      state.cardMode,
      state.toggleCardMode,
      state.darkMode,
      state.toggleDarkMode,
    ]);

  const [user] = useGameStore((state) => [state.user]);

  const router = useRouter();

  function onCardsClick() {
    toggleCardMode();
  }

  function onThemeClick() {
    toggleDarkMode();
  }

  return (
    <Menu as="div" className="relative -mb-1 inline-block text-left sm:mb-0">
      <Menu.Button className="w-full text-sm font-medium text-gray-700 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white sm:py-0">
        <div className="relative inline-flex h-8 w-8 items-center justify-center overflow-hidden rounded-full dark:bg-gray-600 md:h-10 md:w-10">
          <svg
            className="w-5"
            width="18"
            height="24"
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9 12C10.5913 12 12.1174 11.3679 13.2426 10.2426C14.3679 9.11742 15 7.5913 15 6C15 4.4087 14.3679 2.88258 13.2426 1.75736C12.1174 0.632141 10.5913 0 9 0C7.4087 0 5.88258 0.632141 4.75736 1.75736C3.63214 2.88258 3 4.4087 3 6C3 7.5913 3.63214 9.11742 4.75736 10.2426C5.88258 11.3679 7.4087 12 9 12ZM16.3005 24C17.241 24 18 23.31 18 22.4685C18 17.997 13.9695 15 9 15C4.029 15 0 17.9955 0 22.4685C0 23.313 0.7635 24 1.6995 24H16.3005Z"
              fill="black"
            />
          </svg>
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static={true}
          className="min-w absolute right-0 z-50 mt-1 w-56 min-w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="px-1 py-1 ">
            <div>
              <div
                className={`${"text-black"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
              >
                <div className="flex flex-col items-start">
                  <div className="flex items-center justify-center truncate">
                    <img
                      className="mr-3 h-6 w-6"
                      src={`/img/flags/${user.country.code}.svg`}
                      alt={`${user.country.name} flag`}
                    ></img>

                    <div>
                      <p className="font-medium">{user.displayName} </p>

                      <p className="text-xs text-gray-500">
                        {user.email}
                        {/* {user.firstName} {user.lastName} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user?.roles?.includes("ROLE_ADMIN") && (
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/admin/grid`}
                    className={`${
                      active ? "bg-soft-grey text-black" : "text-black"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <span className="w-7">
                      <IconAdminLine className="h-5 w-5" />
                    </span>
                    Admin Panel
                  </a>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  // href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/account/logout`}
                  href={`https://${process.env.NEXT_PUBLIC_SSO_URL}/sso/?action=logout&r=https://${process.env.NEXT_PUBLIC_CALLBACK_URL}/account/logout`}
                  className={`${
                    active ? "bg-soft-grey text-black" : "text-black"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <span className="w-7">
                    <IconExit className="h-5 w-5" aria-hidden="true" />
                  </span>
                  Logout
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function IconSun(props) {
  return (
    <svg
      id="theme-toggle-light-icon"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function IconMoon(props) {
  return (
    <svg
      id="theme-toggle-light-icon"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
    </svg>
  );
}

function IconExit2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
      />
    </svg>
  );
}

function IconExit(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M4 12a1 1 0 001 1h7.59l-2.3 2.29a1 1 0 000 1.42 1 1 0 001.42 0l4-4a1 1 0 00.21-.33 1 1 0 000-.76 1 1 0 00-.21-.33l-4-4a1 1 0 10-1.42 1.42l2.3 2.29H5a1 1 0 00-1 1zM17 2H7a3 3 0 00-3 3v3a1 1 0 002 0V5a1 1 0 011-1h10a1 1 0 011 1v14a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-2 0v3a3 3 0 003 3h10a3 3 0 003-3V5a3 3 0 00-3-3z" />
    </svg>
  );
}

function EditInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  );
}

function EditActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  );
}

function DuplicateInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  );
}

function DuplicateActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  );
}

function ArchiveInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="8"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="4"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  );
}

function ArchiveActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="8"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="4"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  );
}

function MoveInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H16V10" stroke="#A78BFA" strokeWidth="2" />
      <path d="M16 4L8 12" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6H4V16H14V12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  );
}

function MoveActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H16V10" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M16 4L8 12" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6H4V16H14V12" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  );
}

function DeleteInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  );
}

function DeleteActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  );
}
